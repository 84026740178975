const res = {
    success: function(response) {
        if (response.data.code == 200 || response.data.code == 0 ) {
            return true;
        }
        return false;
    },

    config: {
        agentId: '1000059',
        corpId:'wxee07e0adf516838a',
        appName: "qywx_app16"
    }
}

export default res;
