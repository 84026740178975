function scanQRCode(callback) {
    wx.scanQRCode({
        desc: '扫描货号',
        needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
        success: function(res) {
            console.log(res);
            callback(res.resultStr);
            // 回调
        },
        error: function(res) {
            callback(null);
            if (res.errMsg.indexOf('function_not_exist') > 0) {
                alert('版本过低请升级');
            } else {
                alert(res.errMsg);
            }
        }
    });
}

export default {
    scanQRCode
}
