//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import wxHelper from './common/wxHelper'
import networking from './common/networking'
import utils from './common/utils'
import api from './common/api'
import {LocalStorage} from "quasar";
export default {
    name: "Home",
    data () {
        return {
            matnrs: [],
            fullscreenLoading: false,
            searchText: '',
            qrcode: require('@/assets/shoppingGuideProducts/qrcode.png')
        }
    },
    methods: {
        handleScan: function () {
            console.log('handleScan');
        // this.fullscreenLoading = true;
        //             networking.requestScanAPI('EBB216027U11950', (products) => {
        //                 this.searchText = '';
        //                 //alert(JSON.stringify(products));
        //                 if (products.length == 1) {
        //                     this.pushNextPage(products[0], 'Detail');
        //                 } else {
        //                     // this.$router.push({name: 'list', params: {isScan: true, products: products}});
        //                     this.pushNextPage(products, 'List');
        //                 }
        //             }, () => {
        //                 this.fullscreenLoading = false;
        //             })
        //  return

            wxHelper.scanQRCode((resultStr) => {
                console.log(resultStr);
                if (resultStr) {
                    this.fullscreenLoading = true;
                    networking.requestScanAPI(resultStr, (products) => {
                        this.searchText = '';
                        //alert(JSON.stringify(products));
                        if (products.length == 1) {
                            this.pushNextPage(products[0], 'Detail');
                        } else {
                            // this.$router.push({name: 'list', params: {isScan: true, products: products}});
                            this.pushNextPage(products, 'List');
                        }
                    }, () => {
                        this.fullscreenLoading = false;
                    })
                }
            })
        },
        handleSearch: function () {
            console.log('handleSearch');
            let text = this.searchText.trim();
            // if (!utils.checkNumber(text)) {
            //     alert('请输入数字');
            //     return;
            // }

            if (text.length < 5 || text.length > 6) {
                alert('请输入5位或6位货号短码');
                return;
            }

            let matnr = text.toUpperCase();
            this.fullscreenLoading = true;
            networking.requestSearchAPI(matnr, (products) => {
                console.log(products);
                // alert(JSON.stringify(products));
                this.matnrs = utils.getCahceMatnrs();
                if (products.length == 0) {
                    alert('没有查询到相关数据');
                } else if (products.length == 1) {
                    this.pushNextPage(products[0], 'Detail');
                } else {
                    this.pushNextPage(products, 'List');
                }
            }, () => {
                this.fullscreenLoading = false;
            })
        },
        pushNextPage(products, name) {
            if (name == 'List') {
                for (let item of products) {
                    item.image = api.imageHost + item.img;
                }
            } else {
                products.image = api.imageHost + products.img;
            }
            LocalStorage.set('productParams', JSON.stringify(products));
            this.$router.push({path:`/shoppingGuideProducts/${name}`});
        },
        querySearch(queryString, cb) {
            let matnrs = this.matnrs;
            matnrs = matnrs.map(value => {
                return {value: value};
            })
            console.log(matnrs);
            cb(matnrs);
        },
        getQueryString: function(name) {
            let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            let r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            };
            return null;
        }
    },
    mounted() {
        document.title = '单款解析';
        this.matnrs = utils.getCahceMatnrs();
        console.log(this.matnrs);

        networking.requestJsSdkConfig()


        // let code = this.getQueryString('code');
        // networking.requestSignatureAPI(() => {
        //     // alert('requestSignatureAPI')
        //     console.log('done');
        // }, code);
        // networking.requestSearchAPI('EBB41131S12444', (products) => {
        //     console.log(products);
        // }, () => {
        //
        // })
    }
}
