function checkNumber(val) {
    console.log(val);
    const re = /^[0-9]*$/;
    if (re.test(val)) {
        console.log('test success')
        return true;
    }
    return false;
}

function getCahceMatnrs() {
    let matnrs = window.localStorage.getItem('matnrs');
    if (!matnrs) {
        return [];
    }
    let arr = JSON.parse(matnrs);
    return arr;
    // return arr.map(value => {
    //     return Number(value);
    // })
}

function cacheMatnr(matnr) {
    let matnrs = getCahceMatnrs();
    if (matnrs.indexOf(matnr) == -1) {
        if (matnrs.length == 3) {
            matnrs.pop();
        }
        matnrs.unshift(matnr);
        window.localStorage.setItem('matnrs', JSON.stringify(matnrs));
    }
}

export default {
    checkNumber,
    getCahceMatnrs,
    cacheMatnr
}
