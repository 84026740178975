let host = '';
let imageHost = 'https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/'
export default {
    jsapitickt: host + '/auth/ompLogin',
    search: host + '/consumer/v1/wx/cp/product/list',
    getJsSdkConfig: host + '/consumer/v1/wx/cp/getJsSdkConfig',
    detail: host + '/consumer/v1/wx/cp/product/get',
    scan: host + '/consumer/v1/wx/cp/product/scan',
    imageHost
}


