import axios from 'axios'
import api from './api'
import common from './common'
import utils from './utils'
import {LocalStorage} from "quasar";

function requestScanAPI(matnr, callback, finallyCallback) {
    axios.get(`${api.scan}?matnr=${matnr.toUpperCase()}`).then((response) => {
        console.log(response);
        if (common.success(response)) {
            callback(response.data.data);
        } else {
            alert('未扫描到产品');
        }
    }).catch((error) => {
        console.log(error);
        // alert(error);
        alert('未扫描到产品');
    }).then(() => {
        finallyCallback();
    });
}

function requestSearchAPI(matnr, callback, finallyCallback) {
    // let formData = new FormData();
    // formData.append('matnr', matnr);
    axios.get(`${api.search}?matnr=${matnr}`).then(function (response) {
        if (common.success(response)) {
            utils.cacheMatnr(matnr);
            let products = response.data.data;
            callback(products);
        } else {
            alert(response.data.msg);
            //callback([]);
        }
        console.log(response);
    })
        .catch(function (error) {
            // handle error
            console.log(error);
            alert(error);
        })
        .then(function () {
            // that.fullscreenLoading = false;
            finallyCallback();
        });
}

function requestSignatureAPI(callback, code) {
    axios.post(api.jsapitickt, {
        appName: common.config.appName,
        code: code,
        source: "qywx"
    }).then((response) => {
        console.log(response);
        if (common.success(response)) {
            let data = response.data.data;
            let accessToken = data.access_token;
            localStorage.setItem('accessToken', accessToken);
            requestJsSdkConfig();
        } else {
            alert('login失败')
        }
    }).catch((error) => {
        console.log(error);
        alert(error);
    }).then(() => {
        callback();
    });
}

function requestJsSdkConfig() {
    // let agentId = common.config.agentId;
    // let corpId = common.config.corpId;
    let agentId = LocalStorage.getItem("agentId");
    let corpId = LocalStorage.getItem("corpId");
    axios.get(api.getJsSdkConfig, {
        params: {
            agentId,
            corpId,
            url: encodeURIComponent(window.location.href.split('#')[0]),
        }
    })
        .then(function (response) {
            // alert(JSON.stringify(response.data));
            console.log(response.data.data);
            if (common.success(response)) {
                let data = response.data.data;
                let wxConfigData = {nonceStr: data.nonceStr, timestamp: data.timestamp, signature: data.signature, appId: data.appId};
                wxConfig(wxConfigData);
            } else {
                alert('获取JsSdkConfig失败：' + response.data.reason);
            }
        })
        .catch(function (error) {
            console.log(error);
            alert('获取JsSdkConfig失败：' + error);
        })
        .then(function () {

        });
}

function wxConfig(data) {
    // alert(JSON.stringify(data));
    wx.config({
        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，企业微信的corpID
        timestamp: data.timestamp, //  必填，生成签名的时间戳
        nonceStr: data.nonceStr, //  必填，生成签名的随机串
        signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ['scanQRCode', 'chooseImage', 'uploadImage', 'previewImage', 'getLocalImgData'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
    });

    wx.ready(function () {
        console.log('reday');
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
    });

    wx.error(function (res) {
        console.log(res);
        alert(JSON.stringify(res));
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    });
}

export default {
    requestSearchAPI,
    requestSignatureAPI,
    requestScanAPI,
    requestJsSdkConfig
}
